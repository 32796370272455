import React, { useEffect, useMemo } from "react";
import { Box, Paper, CircularProgress, Backdrop } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import BaseFilterBar from "./BaseFilterBar";
import AttributeFilterSideBar from "./AttributeFilterSideBar";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import PerfectScrollbar from "react-perfect-scrollbar";
import Api from "../../components/Api";
import { actionTypes as appActionTypes } from "../../redux/app/actionTypes";
import {
  privateApiGET,
  privateApiPOSTWithBaseFilter,
} from "../../components/PrivateRoute";

import clsx from "clsx";

import { uniqBy } from "lodash";
import { menuItems } from "../../menu-items";

const useStyles = makeStyles((theme) => ({
  content: {
    // minHeight: "100vh",
    // background: "#f7f6f6",
  },
  headerSection: {
    position: "fixed",
    //height: "150px",
    width: "100%",
    zIndex: "1001",
    left: 0,
    top: 0,
    backgroundColor: "#ffffff",
    borderBottom: `1px solid ${config.borderColor}`,
    boxShadow: "rgb(0 0 0 / 6%) 0px 3px 8px",
  },
  attributeSideBarWrapper: {
    position: "fixed",
    top: "180px", //175px
    left: "20px",
    width: "280px",
    height: "calc(100vh - 200px)", //calc(100vh - 195px)
    minHeight: "350px",
    border: `1px solid ${config.borderColor}`,
    padding: "20px",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
      // width: "96%",
      // height: "auto",
      // position: "absolute",
      // minHeight: "20px",
      // left: "0",
      // top: "170px",
      // left: "2%",
      // right: "2%",
      // zIndex: 100,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    // ["@media (max-width:1050px)"]: {
    //   top: "235px",
    // },
  },
  mainSection: {
    padding: "180px 20px 20px 330px",
    "& .noSidebar": {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "85px !important",
      paddingBottom: "30px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fff",
  },
}));

const MainLayout = ({}) => {
  // Profile API comes here & initial loading and settting up pages happen here

  const { initialAppLoading } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const classes = useStyles();

  const menuItem = useMemo(
    () => menuItems.find((each) => each.url === pathname),
    [pathname]
  );
  const handleInitialSetup = () => {
    dispatch({
      type: appActionTypes.SET_INITIAL_SETUP_FETCH,
      payload: {},
    });

    privateApiGET(Api.profile)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          const { user_access_control, user_basic_info, user_plan } =
            data?.data;

          let selectedReport = user_access_control[0];

          let obj = {
            retailerAccessList: uniqBy(
              user_access_control?.map((each) => each["retailer"]),
              "id"
            ),
            activeRetailer: selectedReport?.["retailer"]
              ? [selectedReport?.["retailer"]]
              : [],
            categoryAccessList: uniqBy(
              user_access_control?.map((each) => each["category"]),
              "id"
            ),
            activeCategory: selectedReport?.["category"]
              ? [selectedReport?.["category"]]
              : [],
            subCategoryAccessList: uniqBy(
              user_access_control?.map((each) => each["sub_category"]),
              "id"
            ),
            activeSubCategory: selectedReport?.["sub_category"]
              ? [selectedReport?.["sub_category"]]
              : [],
          };

          dispatch({
            type: appActionTypes.SET_INITIAL_SETUP_SUCCESS,
            payload: {
              userInfo: user_basic_info,
              userPlan: user_plan,
              userAccessControl: user_access_control,
              ...obj,
            },
          });
        } else if (status === 204) {
          dispatch({
            type: appActionTypes.SET_INITIAL_SETUP_FAILED,
            payload: {},
          });
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          console.log("INVALID TOKEN");
          localStorage.removeItem("token");
          navigate("/login");
          return;
        }

        console.log("error", err);

        dispatch({
          type: appActionTypes.SET_INITIAL_SETUP_FAILED,
          payload: {},
        });
      })

      .finally(() => console.log("PROFILE API EXECUTED"));
  };

  useEffect(() => {
    handleInitialSetup();
    return () => {};
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={initialAppLoading}>
        <CircularProgress color="primary" />
      </Backdrop>

      {!initialAppLoading && (
        <div className={classes.content}>
          <div className={classes.headerSection}>
            <Navbar />
            <BaseFilterBar />
          </div>
          {!menuItem.disableAttrFilter ? (
            <Paper className={classes.attributeSideBarWrapper} elevation={8}>
              <PerfectScrollbar>
                <AttributeFilterSideBar />
              </PerfectScrollbar>
            </Paper>
          ) : null}

          <div
            className={clsx({
              [classes.mainSection]: true,
              pl20: menuItem.disableAttrFilter,
            })}
          >
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default MainLayout;
