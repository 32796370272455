const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  basename: "",
  defaultPath: "/app/search",
  defaultPathID: "home",
  fontFamily: `'Poppins', sans-serif`,
  fontSize: 14,
  backgroundColor: "#fffbf6", // Yellow
  borderColor: "#ede6df", //#e7ddd1 //ede6df //#efe6db
  // backgroundColor: "#ffecf4", // Violet #fdf0f5
  // borderColor: "#edd7e1",
  borderRadius: 10,
  borderRadiusSmall: 4,
  gridSpacing: 3,
  gridSpacingSm: 2,
};

export default config;
