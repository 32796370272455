import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";

// auth pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));

// app's
const SearchPage = Loadable(
  lazy(() => import("../views/SearchPage/search.page"))
);
const HomePage = Loadable(lazy(() => import("../views/Home/home.page")));

const BrandAnalysisPage = Loadable(
  lazy(() => import("../views/BrandAnalysis/brand-analysis.page"))
);
const CategoryAnalysisPage = Loadable(
  lazy(() => import("../views/CategoryAnalysis/category-analysis.page"))
);
const NeedsPage = Loadable(lazy(() => import("../views/Needs/needs.page")));

const AwarenessPage = Loadable(
  lazy(() => import("../views/Awareness/awareness.page"))
);
const FeedbackPage = Loadable(
  lazy(() => import("../views/Feedback/feedback.page"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../views/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../views/auth/ResetPassword"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />
      <Route
        path="app"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route path="brand-analysis" element={<BrandAnalysisPage />} />
        <Route path="category-analysis" element={<CategoryAnalysisPage />} />
        <Route path="needs" element={<NeedsPage />} />
        <Route path="awareness" element={<AwarenessPage />} />
        <Route path="feedback" element={<FeedbackPage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="search" element={<SearchPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/reset-password/:uidb64/:token"
        element={<ResetPassword />}
      />
    </Routes>
  );
};

export default AppRoutes;
