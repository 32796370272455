import { actionTypes } from "./actionTypes";

const initialState = {
  // search queries
  isSearchQueriesDataLoading: false,
  searchQueriesData: [],
  searchQueriesDataExceptionMessage: "",

  searchQuery: "",

  // search query results
  isSearchResultsDataLoading: false,
  searchResultsData: {},
  searchResultsDataExceptionMessage: "",
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SEARCH_QUERY_FETCH:
      return {
        ...state,
        isSearchQueriesDataLoading: true,
        searchQueriesData: [],
        ...payload,
      };
    case actionTypes.SET_SEARCH_QUERY_SUCCESS:
      return {
        ...state,
        isSearchQueriesDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_SEARCH_QUERY_FAILED:
      return {
        ...state,
        isSearchQueriesDataLoading: false,
        searchQueriesData: [],
        ...payload,
      };
    case actionTypes.SET_SEARCH_QUERY:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.SET_SEARCH_RESULTS_FETCH:
      return {
        ...state,
        isSearchResultsDataLoading: true,
        searchResultsData: {},
        ...payload,
      };
    case actionTypes.SET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        isSearchResultsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        isSearchResultsDataLoading: false,
        searchResultsData: {},
        ...payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
