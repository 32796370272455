// action - state management
import { actionTypes } from "./actionTypes";

export const initialState = {
  initialAppLoading: true,

  // profile Data
  userInfo: {},
  userPlan: {},
  userAccessControl: [],
  initialLoadingError: "",

  // Main Filters
  retailerAccessList: [],
  activeRetailer: [],
  categoryAccessList: [],
  activeCategory: [],
  subCategoryAccessList: [],
  activeSubCategory: [],

  // attribute filter state's
  isAttributeDataLoading: false,
  attributeFilterOptionsData: {},
  attributeDataLoadingError: "",
  selectedAttributeData: {},
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_INITIAL_SETUP_FETCH:
      return {
        ...state,
        initialAppLoading: true,
        ...payload,
      };

    case actionTypes.SET_INITIAL_SETUP_SUCCESS:
      return {
        ...state,
        initialAppLoading: false,
        ...payload,
      };

    case actionTypes.SET_INITIAL_SETUP_FAILED:
      return {
        ...state,
        initialAppLoading: false,
        ...payload,
      };

    case actionTypes.SET_BASE_FILTER_FIELDS:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.SET_ATTRIBUTE_OPTIONS_DATA_FETCH:
      return {
        ...state,
        isAttributeDataLoading: true,
        ...payload,
      };

    case actionTypes.SET_ATTRIBUTE_OPTIONS_DATA_SUCCESS:
      return {
        ...state,
        isAttributeDataLoading: false,
        ...payload,
      };

    case actionTypes.SET_ATTRIBUTE_OPTIONS_DATA_FAILED:
      return {
        ...state,
        isAttributeDataLoading: false,
        ...payload,
      };

    case actionTypes.SET_SELECTED_ATTRIBUTE_DATA:
      return {
        ...state,
        selectedAttributeData: payload,
      };

    default:
      return state;
  }
};

export default appReducer;
