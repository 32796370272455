import { brandAnalysisViewByConstant } from "../../constants";
import { actionTypes } from "./actionTypes";

const initialState = {
  // bar-chart
  isBrandDataLoading: false,
  brandDataData: {},
  brandDataExceptionMessage: "",
  viewBy: brandAnalysisViewByConstant,

  // Metics
};

const brandAnalysisReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_BRAND_DATA_FETCH:
      return {
        ...state,
        isBrandDataLoading: true,
        brandDataData: {},
        ...payload,
      };
    case actionTypes.SET_BRAND_DATA_SUCCESS:
      return { ...state, isBrandDataLoading: false, ...payload };
    case actionTypes.SET_BRAND_DATA_FAILED:
      return {
        ...state,
        isBrandDataLoading: false,
        brandDataData: {},
        ...payload,
      };
    case actionTypes.SET_BRAND_DATA_VIEW_BY:
      return { ...state, viewBy: payload };
    default:
      return state;
  }
};

export default brandAnalysisReducer;
