import { actionTypes } from "./actionTypes";

const initialState = {
  xPlain: "",
  yPlain: "",
  zPlain: "",
  isCategoryLoading: false,
  categoryData: [],
  categoryExceptionMessage: "",
};

const categoryAnalysisReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PLAINS:
      return { ...state, ...payload };
    case actionTypes.SET_CATEGORY_DATA_FETCH:
      return {
        ...state,
        isCategoryLoading: true,
        categoryData: [],
        ...payload,
      };
    case actionTypes.SET_CATEGORY_DATA_SUCCESS:
      return { ...state, isCategoryLoading: false, ...payload };
    case actionTypes.SET_CATEGORY_DATA_FAILED:
      return {
        ...state,
        isCategoryLoading: false,
        categoryData: [],
        ...payload,
      };
    default:
      return state;
  }
};

export default categoryAnalysisReducer;
