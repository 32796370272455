import React from "react";
import { Avatar } from "@mui/material";

const NavLogo = () => {
  return (
    <Avatar
      variant="square"
      alt="Ongil"
      src="/static/img/ongil.png"
      sx={{ width: 55, height: 55, bgcolor: "transparent" }}
    />
  );
};

export default NavLogo;
