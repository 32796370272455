export const actionTypes = {
  SET_SEARCH_QUERY_FETCH: "SET_SEARCH_QUERY_FETCH",
  SET_SEARCH_QUERY_SUCCESS: "SET_SEARCH_QUERY_SUCCESS",
  SET_SEARCH_QUERY_FAILED: "SET_SEARCH_QUERY_FAILED",

  SET_SEARCH_QUERY: "SET_SEARCH_QUERY",

  SET_SEARCH_RESULTS_FETCH: "SET_SEARCH_RESULTS_FETCH",
  SET_SEARCH_RESULTS_SUCCESS: "SET_SEARCH_RESULTS_SUCCESS",
  SET_SEARCH_RESULTS_FAILED: "SET_SEARCH_RESULTS_FAILED",
};
