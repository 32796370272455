export const actionTypes = {
  // Fetch keywords
  SET_KEYWORDS_FETCH: "SET_KEYWORDS_FETCH",
  SET_KEYWORDS_SUCCESS: "SET_KEYWORDS_SUCCESS",
  SET_KEYWORDS_FAILED: "SET_KEYWORDS_FAILED",

  // Set keywords
  SET_SELECTED_KEYWORDS: "SET_SELECTED_KEYWORDS",

  // Fetch product types sov
  SET_PRODUCT_TYPES_SOV_FETCH: "SET_PRODUCT_TYPES_SOV_FETCH",
  SET_PRODUCT_TYPES_SOV_SUCCESS: "SET_PRODUCT_TYPES_SOV_SUCCESS",
  SET_PRODUCT_TYPES_SOV_FAILED: "SET_PRODUCT_TYPES_SOV_FAILED",

  // Fetch brands sov
  SET_BRANDS_SOV_FETCH: "SET_BRANDS_SOV_FETCH",
  SET_BRANDS_SOV_SUCCESS: "SET_BRANDS_SOV_SUCCESS",
  SET_BRANDS_SOV_FAILED: "SET_BRANDS_SOV_FAILED",
};
