import { feedbackViewByConstant } from "../../constants";
import { actionTypes } from "./actionTypes";

const initialState = {
  // Topics
  isTopicsDataLoading: false,
  topicsData: [],
  topicsDataExceptionMessage: "",
  selectedTopic: "",
  selectedAttrType: feedbackViewByConstant,

  // Topic subcats
  isTopicSubcatsDataLoading: false,
  topicSubcatsData: [],
  topicSubcatsDataExceptionMessage: "",

  // Topic products
  isTopicProductsDataLoading: false,
  topicProductsData: [],
  topicProductsDataExceptionMessage: "",
  selectedProduct: "",

  // Product reviews
  isProductReviewsDataLoading: false,
  productReviewsData: [],
  productReviewsDataExceptionMessage: "",
};

const feedbackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_TOPICS_FETCH:
      return {
        ...state,
        isTopicsDataLoading: true,
        topicsData: [],
        ...payload,
      };
    case actionTypes.SET_TOPICS_SUCCESS:
      return {
        ...state,
        isTopicsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_TOPICS_FAILED:
      return {
        ...state,
        isTopicsDataLoading: false,
        topicsData: [],
        ...payload,
      };
    case actionTypes.SET_SELECTED_TOPIC:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.SET_SETLECTED_ATTR_TYPE:
      return {
        ...state,
        selectedAttrType: payload,
      };
    case actionTypes.SET_TOPIC_SUBCATS_FETCH:
      return {
        ...state,
        isTopicSubcatsDataLoading: true,
        topicSubcatsData: [],
        ...payload,
      };
    case actionTypes.SET_TOPIC_SUBCATS_SUCCESS:
      return {
        ...state,
        isTopicSubcatsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_TOPIC_SUBCATS_FAILED:
      return {
        ...state,
        isTopicSubcatsDataLoading: false,
        topicSubcatsData: [],
        ...payload,
      };
    case actionTypes.SET_TOPIC_PRODUCTS_FETCH:
      return {
        ...state,
        isTopicProductsDataLoading: true,
        topicProductsData: [],
        ...payload,
      };
    case actionTypes.SET_TOPIC_PRODUCTS_SUCCESS:
      return {
        ...state,
        isTopicProductsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_TOPIC_PRODUCTS_FAILED:
      return {
        ...state,
        isTopicProductsDataLoading: false,
        topicProductsData: [],
        ...payload,
      };
    case actionTypes.SET_PRODUCT_REVIEWS_FETCH:
      return {
        ...state,
        isProductReviewsDataLoading: true,
        productReviewsData: [],
      };
    case actionTypes.SET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        isProductReviewsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_PRODUCT_REVIEWS_FAILED:
      return {
        ...state,
        isProductReviewsDataLoading: false,
        productReviewsData: [],
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default feedbackReducer;
