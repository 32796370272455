import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  List,
  ListItem,
  Typography,
  ListSubheader,
  Button,
} from "@mui/material";
import CustomAutoComplete from "../../../components/CustomAutoComplete";

// Request helpers
import { actionTypes as appActionTypes } from "../../../redux/app/actionTypes";
import { privateApiPOSTWithBaseFilter } from "../../../components/PrivateRoute";
import Api from "../../../components/Api";
import { startCase } from "lodash";

const AttributeFilterSideBar = () => {
  const [tempInternalState, setTempInternalState] = useState({
    selectedAttributeData: {},
  });

  const dispatch = useDispatch();

  const {
    activeRetailer,
    activeCategory,
    activeSubCategory,
    attributeFilterOptionsData,
    selectedAttributeData,
  } = useSelector((state) => state.app);

  const fetchAttributeData = () => {
    dispatch({
      type: appActionTypes.SET_ATTRIBUTE_OPTIONS_DATA_FETCH,
      payload: {},
    });
    privateApiPOSTWithBaseFilter(Api.attributeApi, {})
      .then((res) => {
        const { data, status } = res;
        if (status == 200) {
          console.log("attribute api", data);

          dispatch({
            type: appActionTypes.SET_ATTRIBUTE_OPTIONS_DATA_SUCCESS,
            payload: {
              attributeFilterOptionsData: data?.data,
            },
          });
        } else {
          dispatch({
            type: appActionTypes.SET_ATTRIBUTE_OPTIONS_DATA_FAILED,
            payload: {
              attributeFilterOptionsData: {},
            },
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch({
          type: appActionTypes.SET_ATTRIBUTE_OPTIONS_DATA_FAILED,
          payload: {
            attributeFilterOptionsData: {},
          },
        });
      })
      .finally(() => console.log("ATTRIBUTE API EXECUTED"));
  };

  useEffect(() => {
    setTempInternalState((prev) => ({ ...prev, selectedAttributeData }));
  }, [selectedAttributeData]);

  const handleAttributeChange = (key, value) => {
    const newAttributeValue = { ...tempInternalState.selectedAttributeData };

    if (value.length === 0) {
      delete newAttributeValue[key];
    } else {
      newAttributeValue[key] = value;
    }

    setTempInternalState((prev) => ({
      ...prev,
      selectedAttributeData: newAttributeValue,
    }));
  };

  const handleApplyAttributeChanges = () => {
    // if (!Object.keys(tempInternalState.selectedAttributeData).length > 0)
    //   return;
    dispatch({
      type: appActionTypes.SET_SELECTED_ATTRIBUTE_DATA,
      payload: tempInternalState.selectedAttributeData,
    });
  };

  useEffect(() => {
    if (
      activeRetailer?.length === 0 ||
      activeCategory?.length === 0 ||
      activeSubCategory?.length === 0
    ) {
      return;
    }

    fetchAttributeData();

    return () => {};
  }, [
    activeRetailer,
    activeCategory,
    activeSubCategory,
    window.location.pathname,
  ]);

  return (
    <>
      <ListSubheader
        sticky="true"
        sx={{
          padding: "5px 0",
          zIndex: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Attribute Filters</Typography>
        <Button
          size="small"
          variant="contained"
          onClick={handleApplyAttributeChanges}
        >
          Apply
        </Button>
      </ListSubheader>
      <List>
        {Object.keys(attributeFilterOptionsData)?.map((each) => (
          <ListItem key={each} sx={{ mb: 1 }}>
            <CustomAutoComplete
              isLoading={false}
              attributeName={startCase(each)}
              multiple
              limitTags={1}
              size="small"
              fullWidth
              options={attributeFilterOptionsData[each]}
              value={tempInternalState.selectedAttributeData?.[each] || []}
              getOptionLabel={(option) => option || []}
              onChange={(event, newValue) => {
                handleAttributeChange(each, newValue);
              }}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

AttributeFilterSideBar.propTypes = {};

export default AttributeFilterSideBar;
