import { combineReducers } from "redux";

// reducer import
import appReducer from "./app/reducers";
import feedbackReducer from "./feedback/reducer";
import awarenessReducer from "./awareness/reducer";
import brandAnalysisReducer from "./brandAnalysis/reducer";
import categoryAnalysisReducer from "./categoryAnalysis/reducer";
import searchReducer from "./search/reducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
  app: appReducer,
  feedback: feedbackReducer,
  awareness: awarenessReducer,
  brandAnalysis: brandAnalysisReducer,
  categoryAnalysis: categoryAnalysisReducer,
  search: searchReducer,
});

export default reducer;
