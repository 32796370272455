import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Button,
  Box,
  Grid,
  useMediaQuery,
  ButtonBase,
} from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import { actionTypes as appActionTypes } from "../../../redux/app/actionTypes";
import { startCase } from "lodash";
import globalUseStyles from "../../../components/GlobalStyles";

const BaseFilterBar = () => {
  const dispatch = useDispatch();
  const [tempInternalState, setTempInternalState] = useState({
    retailer: [],
    category: [],
    subCategory: [],
  });

  const {
    retailerAccessList,
    activeRetailer,
    categoryAccessList,
    activeCategory,
    subCategoryAccessList,
    activeSubCategory,
  } = useSelector((state) => state.app);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setTempInternalState({
      retailer: activeRetailer,
      category: activeCategory,
      subCategory: activeSubCategory,
    });
  }, [activeRetailer, activeCategory, activeSubCategory]);

  const handleDropDownChange = (id, value) => {
    setTempInternalState((prev) => ({ ...prev, [id]: value }));
  };

  const handleApplyFieldChanges = () => {
    dispatch({
      type: appActionTypes.SET_BASE_FILTER_FIELDS,
      payload: {
        activeCategory: tempInternalState.category,
        activeRetailer: tempInternalState.retailer,
        activeSubCategory: tempInternalState.subCategory,
      },
    });
  };

  const globalClasses = globalUseStyles();
  return (
    <Box
      sx={{
        paddingTop: "25px",
        paddingBottom: "20px",
        paddingRight: "130px",
        backgroundColor: "#ffffff",
        position: "relative",
        display: smallScreen ? "none" : "block",
      }}
    >
      <Container maxWidth="">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              flexWrap="nowrap"
              overflow="auto"
            >
              <Grid item sx={{ width: 250, flex: "0 0 auto" }}>
                <CustomAutoComplete
                  isLoading={false}
                  attributeName={"Retailer"}
                  multiple
                  limitTags={1}
                  size="small"
                  options={retailerAccessList}
                  value={tempInternalState.retailer || []}
                  getOptionLabel={(option) => startCase(option?.name) || ""}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    handleDropDownChange("retailer", newValue);
                  }}
                />
              </Grid>
              <Grid item sx={{ width: 250, flex: "0 0 auto" }}>
                <CustomAutoComplete
                  isLoading={false}
                  attributeName={"Category"}
                  multiple
                  limitTags={1}
                  size="small"
                  options={categoryAccessList}
                  value={tempInternalState.category || []}
                  getOptionLabel={(option) => startCase(option?.name) || ""}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    handleDropDownChange("category", newValue);
                  }}
                />
              </Grid>
              <Grid item sx={{ width: 250, flex: "0 0 auto" }}>
                <CustomAutoComplete
                  isLoading={false}
                  attributeName={"Subcategory"}
                  multiple
                  limitTags={1}
                  size="small"
                  options={subCategoryAccessList}
                  value={tempInternalState.subCategory || []}
                  getOptionLabel={(option) => startCase(option?.name) || ""}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    handleDropDownChange("subCategory", newValue);
                  }}
                />
              </Grid>
              <Grid item sx={{ width: 250 }}>
                <Button onClick={handleApplyFieldChanges} variant="contained">
                  APPLY
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Button
        className={globalClasses.exportButton}
        variant="outlined"
        startIcon={<CloudDownload />}
      >
        Export
      </Button>
    </Box>
  );
};

export default BaseFilterBar;
