import React, { useState } from "react";
import config from "../../../config";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { menuItems } from "../../../menu-items";
import { IconBrandHipchat } from "@tabler/icons";

//Material UI
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  ClickAwayListener,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Logout, NoEncryption } from "@mui/icons-material";
import NavLogo from "./NavLogo";
import globalUseStyles from "../../../components/GlobalStyles";
import { themeColors } from "../../../theme/themeColors";
import Api from "../../../components/Api";
import axios from "axios";
import { useSelector } from "react-redux";

import { startCase, toLower } from "lodash";
import { botURL } from "../../../constants";

const Navbar = () => {
  const { userInfo } = useSelector((state) => state.app);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (url) => {
    navigate(url);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // User Logout
  const handleUserLogout = () => {
    const url = Api.logout;
    const token = localStorage.getItem("token");
    console.log("token", token);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    axios
      .post(url, {}, options)
      .then((res) => {
        localStorage.removeItem("token");
        navigate("/login");
      })
      .catch((error) => {
        localStorage.removeItem("token");
        navigate("/login");
      });
  };

  const globalClasses = globalUseStyles();
  return (
    <AppBar
      position="static"
      sx={{
        position: "relative",
        backgroundColor: "#ffffff",
        borderBottom: `1px solid ${config.borderColor}`,
        boxShadow: "none",
      }}
    >
      <Container maxWidth="">
        <Toolbar disableGutters variant="dense">
          {/* Desktop Logo - START */}
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
            <NavLogo />
          </Box>
          {/* Desktop Logo - END */}

          {/* Mobile Menu - START */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              // color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar-mobile"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {menuItems.map((each) => (
                <MenuItem
                  key={each.id}
                  onClick={() => handleCloseNavMenu(each.url)}
                >
                  <Typography textAlign="center">{each.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Mobile Menu - END */}

          {/* Mobile Logo - START */}
          <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            <NavLogo />
          </Box>
          {/* Mobile Logo - END */}

          {/* Desktop Menu - START */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {menuItems.map((each) => (
              <Button
                key={each.id}
                // onClick={handleCloseNavMenu}
                sx={{
                  my: 1,
                  mr: 1,
                  px: 2,
                  color: "grey.900",
                  display: "block",
                }}
                onClick={() => {
                  navigate(each.url);
                }}
                className={
                  pathname === each.url ? globalClasses.activeState : ""
                }
              >
                {each.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              sx={{ mr: 3, p: "5px 10px" }}
              variant="contained"
              color="secondary"
              startIcon={<IconBrandHipchat />}
              onClick={() => window.open(botURL, "_blank")}
            >
              Chatbot
            </Button>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, textTransform: "uppercase" }}
              variant="outlined"
              color="primary"
            >
              <Avatar
                color="secondary"
                sx={{ backgroundColor: "#fce8e7", color: "#ee3518" }}
              >
                {userInfo["name"]?.[0]}
              </Avatar>
            </IconButton>

            <Menu
              sx={{ mt: "45px" }}
              id="profile-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ pointerEvents: "none" }}>
                <Box sx={{ minWidth: "190px" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                    Hi, {startCase(toLower(userInfo["name"] ?? ""))}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: "12px", color: "grey.600" }}
                  >
                    {userInfo["email"] ?? ""}
                  </Typography>
                </Box>
              </MenuItem>

              <Divider />

              <MenuItem onClick={handleUserLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
          {/* Desktop Menu - END */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
