// BASE FILTER

export const actionTypes = {
  SET_INITIAL_SETUP_FETCH: "SET_INITIAL_SETUP_FETCH",
  SET_INITIAL_SETUP_SUCCESS: "SET_INITIAL_SETUP_SUCCESS",
  SET_INITIAL_SETUP_FAILED: "SET_INITIAL_SETUP_FAILED",
  SET_BASE_FILTER_FIELDS: "SET_BASE_FILTER_FIELDS",
  SET_ATTRIBUTE_OPTIONS_DATA_FETCH: "SET_ATTRIBUTE_OPTIONS_DATA_FETCH",
  SET_ATTRIBUTE_OPTIONS_DATA_SUCCESS: "SET_ATTRIBUTE_OPTIONS_DATA_SUCCESS",
  SET_ATTRIBUTE_OPTIONS_DATA_FAILED: "SET_ATTRIBUTE_OPTIONS_DATA_FAILED",
  SET_SELECTED_ATTRIBUTE_DATA: "SET_SELECTED_ATTRIBUTE_DATA",
};
