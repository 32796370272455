import { actionTypes } from "./actionTypes";

const initialState = {
  // Search Keywords
  isKeywordsDataLoading: false,
  keywordsData: [],
  keywordsDataExceptionMessage: "",

  selectedKeywords: [],

  // Product types SOV
  isProductTypesSovDataLoading: false,
  productTypesSovData: [],
  productTypesSovDataExceptionMessage: "",

  //Brands SOV
  isBrandsSovDataLoading: false,
  brandsSovData: [],
  brandsSovDataExceptionMessage: "",
};

const sovReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_KEYWORDS_FETCH:
      return {
        ...state,
        isKeywordsDataLoading: true,
        keywordsData: [],
        ...payload,
      };
    case actionTypes.SET_KEYWORDS_SUCCESS:
      return {
        ...state,
        isKeywordsDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_KEYWORDS_FAILED:
      return {
        ...state,
        isKeywordsDataLoading: false,
        keywordsData: [],
        ...payload,
      };
    case actionTypes.SET_SELECTED_KEYWORDS:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.SET_PRODUCT_TYPES_SOV_FETCH:
      return {
        ...state,
        isProductTypesSovDataLoading: true,
        productTypesSovData: [],
        ...payload,
      };
    case actionTypes.SET_PRODUCT_TYPES_SOV_SUCCESS:
      return {
        ...state,
        isProductTypesSovDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_PRODUCT_TYPES_SOV_FAILED:
      return {
        ...state,
        isProductTypesSovDataLoading: false,
        productTypesSovData: [],
        ...payload,
      };
    case actionTypes.SET_BRANDS_SOV_FETCH:
      return {
        ...state,
        isBrandsSovDataLoading: true,
        brandsSovData: [],
        ...payload,
      };
    case actionTypes.SET_BRANDS_SOV_SUCCESS:
      return {
        ...state,
        isBrandsSovDataLoading: false,
        ...payload,
      };
    case actionTypes.SET_BRANDS_SOV_FAILED:
      return {
        ...state,
        isBrandsSovDataLoading: false,
        brandsSovData: [],
        ...payload,
      };
    default:
      return state;
  }
};

export default sovReducer;
