export const actionTypes = {
  // Fetch topics
  SET_TOPICS_FETCH: "SET_TOPICS_FETCH",
  SET_TOPICS_SUCCESS: "SET_TOPICS_SUCCESS",
  SET_TOPICS_FAILED: "SET_TOPICS_FAILED",

  // Set Topic
  SET_SELECTED_TOPIC: "SET_SELECTED_TOPIC",

  SET_SETLECTED_ATTR_TYPE: "SET_SETLECTED_ATTR_TYPE",

  // Fetch subcategories by topic
  SET_TOPIC_SUBCATS_FETCH: "SET_TOPIC_SUBCATS_FETCH",
  SET_TOPIC_SUBCATS_SUCCESS: "SET_TOPIC_SUBCATS_SUCCESS",
  SET_TOPIC_SUBCATS_FAILED: "SET_TOPIC_SUBCATS_FAILED",

  // Fetch products by topic
  SET_TOPIC_PRODUCTS_FETCH: "SET_TOPIC_PRODUCTS_FETCH",
  SET_TOPIC_PRODUCTS_SUCCESS: "SET_TOPIC_PRODUCTS_SUCCESS",
  SET_TOPIC_PRODUCTS_FAILED: "SET_TOPIC_PRODUCTS_FAILED",

  // Set product
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",

  // Fetch reviews by product
  SET_PRODUCT_REVIEWS_FETCH: "SET_PRODUCT_REVIEWS_FETCH",
  SET_PRODUCT_REVIEWS_SUCCESS: "SET_PRODUCT_REVIEWS_SUCCESS",
  SET_PRODUCT_REVIEWS_FAILED: "SET_PRODUCT_REVIEWS_FAILED",
};
